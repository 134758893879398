.pl-shim-form-control-label {
  color: $se-gray-700;
  cursor: pointer;
  display: block;
  margin-bottom: $se-spacing-200;
}

.pl-shim-sr-only {   // used for elements that need to be visible for screen readers only
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.pl-shim-fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
