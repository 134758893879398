.user-service-card {
  background: $se-card-background;
  border-radius: $se-card-radius;
  box-shadow: $se-card-shadow;
  padding: $se-card-padding;
}

.user-service-card__header {
  margin-left: -$se-card-padding;
  margin-right: -$se-card-padding;
  margin-top: -$se-card-padding;
  padding: $se-spacing-825 $se-spacing-825;
  border-bottom: 1px solid $se-gray-300;
}

.user-service-card__footer {
  margin-left: -$se-card-padding;
  margin-right: -$se-card-padding;
  margin-bottom: -$se-card-padding;
  margin-top: $se-spacing-825;
  padding: $se-spacing-825 $se-card-padding;
  border-top: 1px solid $se-gray-300;
}

@media (min-width: $tablet-width) {
  .user-service-card {
    padding: $se-card-padding-spacious;
  }

  .user-service-card__header {
    margin-left: -$se-card-padding-spacious;
    margin-right: -$se-card-padding-spacious;
    margin-top: -$se-card-padding-spacious;
    padding: $se-spacing-825 $se-card-padding-spacious;
  }

  .user-service-card__footer {
    margin-left: -$se-card-padding-spacious;
    margin-right: -$se-card-padding-spacious;
    margin-bottom: -$se-card-padding-spacious;
    padding: $se-spacing-825 $se-card-padding-spacious;
  }
}

.user-service-card__header__logo {
  display: block;
  max-width: 250px;
  margin: 0 auto;
  margin-bottom: -6px;
}

.user-service-card__horizontal-rule {
  border-bottom: 1px solid $color-gray-10;
  line-height: .1em;
  margin-top: $layout-spacing-xsmall;

  .user-service-card__horizontal-rule-text {
    background: $se-card-background;
    display: inline;
    padding: 0 1em;
  }
}
