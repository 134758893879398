// # Sticky
// These classes are added by the `sticky` Angular directive.
// They allow us to control how sticky headers and footers appear.

.pl-sticky--top-off {
  position: relative;
  z-index: $z-sticky--top-off;
}
.pl-sticky--bottom-off {
  position: relative;
  z-index: $z-sticky--bottom-off;
}
.pl-sticky--top-on {
  margin: 0 !important;
  z-index: $z-sticky--top-on;

  @for $i from 1 through 4 { // supports top-1 through top-5
    &[sticky='top-#{$i}'] {
      z-index: $z-sticky--top-on + (5 - $i);
    }
  }
}
.pl-sticky--bottom-on {
  margin: 0 !important;
  z-index: $z-sticky--bottom-on;
}
.pl-sticky--clone {
  visibility: hidden;
}
