// Detailed options
// Shows a list of radio or checkbox inputs with details

$pl-detailed-option-spacing: .857rem;

.pl-detailed-option__input {
  left: -9999px;
  position: absolute;
}

.pl-detailed-option__label {
  @extend %pl-option-label;
  align-items: center;
  border: 1px solid $gray-light;
  display: flex;
  min-height: 48px;
  padding: .429rem $pl-detailed-option-spacing; // 6px / 14px, 12px / 14px

  &::before {
    flex: 0 0 auto;
    margin-right: $pl-detailed-option-spacing;
  }

  .pl-detailed-option + .pl-detailed-option & {
    margin-top: -1px;
  }

  .pl-detailed-option:first-child & {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .pl-detailed-option:last-child & {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .pl-detailed-option__subcopy {
    color: $color-content-light;
    font-size: 12px;
    line-height: 1.5;
  }

  // Checked state
  .pl-detailed-option__input:checked + & {
    background-color: $color-highlight-light;
    border-color: $color-highlight;
    z-index: $z-detailed-option--checked;
  }

  // Hover state
  .pl-detailed-option__input:not([disabled]) + &:hover {
    border-color: $gray;
    cursor: pointer;
    z-index: $z-detailed-option;
  }

  // Focus state
  .pl-detailed-option__input:focus + &:not(:hover) {
    z-index: $z-detailed-option--focus;
  }

  // Disabled state
  .pl-detailed-option__input[disabled] + & {
    border-color: $color-content-xxlight;
  }
}

.pl-detailed-option__icon {
  flex-shrink: 0;
  margin-right: $pl-detailed-option-spacing; // 8px / 14px
}

.pl-detailed-option__heading {
  @include font-medium;
  margin-bottom: .1429rem; // 2px / 14px
}

.pl-detailed-option__detail {
  @extend %content-spacing;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;

  & + & {
    margin-left: $pl-detailed-option-spacing; // 8px / 14px
  }
}
