$se-button-min-width:       64px;
$se-button-min-height:      36px;
$se-button-border-radius:   4px;
$se-button-padding:         $se-spacing-300 $se-spacing-550;

$se-button-font-size:   $se-default-font-size;
$se-button-font-family: $se-default-font-family;
$se-button-font-weight: $se-font-weight-semi-bold;

$se-button-base-color:  $se-primary-500;

@mixin se-button-bg-overlay($color) {
  background: linear-gradient($color, $color), $se-white;
}

%se-button {
  align-items: center;
  background: none;
  border: 0;
  border-radius: $se-button-border-radius;
  box-sizing: border-box;
  display: inline-flex;
  font-family: $se-button-font-family;
  font-size: $se-button-font-size;
  font-weight: $se-button-font-weight;
  justify-content: center;
  min-height: $se-button-min-height;
  min-width: $se-button-min-width;
  outline: 0;
  padding: $se-button-padding;
  text-align: center;
  text-transform: uppercase;
}

@import 'button--low';
@import 'button--medium';
@import 'button--high';

.pl-button {
  @extend %se-button;
  @extend %se-button--medium;

  &--full {
    @extend %se-button;
    @extend %se-button--medium;
    width: 100%;
  }
}

.pl-button--minimal {
  @extend %se-button;
  @extend %se-button--low;

  &.pl-button--full {
    width: 100%;
  }
}

.pl-button--highlight,
.pl-button--primary {
  @extend %se-button;
  @extend %se-button--high;

  &.pl-button--full {
    width: 100%;
  }
}