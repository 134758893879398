// # Typography
// Default styles for headings, body copy, lists, etc.

// ## Spacing between elements
// @include mixin in external stylesheets that reference this one
@mixin content-spacing {
  margin-bottom: $text-spacing; // 12px / 14px

  &:last-child {
    margin-bottom: 0;
  }
}

// @extend placeholder in this theme
%content-spacing {
  @include content-spacing;
}

// ## Headings
%heading {
  font-family: $font-heading;
  font-weight: $font-regular;
  margin: 1em 0 .25em;

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

%pl-heading--xxsmall,
.pl-heading--xxsmall {
  @extend %heading;
  @include font-xsmall;
  color: $color-content-light;
}

%pl-heading--xsmall,
.pl-heading--xsmall {
  @extend %heading;
  @include font-small;
  color: $color-content-light;
}

%pl-heading--small,
.pl-heading--small {
  @extend %heading;
  @include font-medium;
  color: $color-content-light;
}

%pl-heading--medium,
.pl-heading--medium {
  @extend %heading;
  @include font-medium;
}

%pl-heading--large,
.pl-heading--large {
  @extend %heading;
  @include font-large;
}

%pl-heading--xlarge,
.pl-heading--xlarge {
  @extend %heading;
  @include font-xlarge;
}

%pl-heading--xxlarge,
.pl-heading--xxlarge {
  @extend %heading;
  @include font-xxxlarge;
}

// ## Images
// Should probably be moved outside of this partial

.pl-list {
  @include content-spacing;
  padding-left: calc(12px + 1.5em);

  li {
    margin-top: .2857em; // 4px / 14px
  }
}

ul.pl-list {
  list-style: disc;
}

ol.pl-list {
  list-style: decimal;
}

// ## Alignment classes
%pl-text,
.pl-text {
  &:not(:first-child) {
    margin-top: $text-spacing;
  }

  &:not(:last-child) {
    margin-bottom: $text-spacing;
  }
}

.pl-text--left {
  text-align: left;
}

.pl-text--right {
  text-align: right;
}

.pl-text--center {
  text-align: center;
}

.pl-text--large {
  @extend %pl-text;
  @include font-medium;
}

.pl-text--xsmall {
  @extend %pl-text;
  @include font-xsmall;
}

.pl-text--xxsmall {
  @extend %pl-text;
  @include font-xxsmall;
}

%pl-text--margin-right,
.pl-text--margin-right {
  @extend %pl-text;
  margin-right: $text-spacing;
}

%pl-text--strikethrough,
.pl-text--strikethrough {
  @extend %pl-text;
  text-decoration: line-through;
}

.pl-text--strikethrough-light {
  @extend %pl-text--strikethrough;
  color: $color-gray-40;
}


%pl-text--bold,
.pl-text--bold {
  @extend %pl-text;
  font-weight: $font-semibold;
}

%pl-text--light,
.pl-text--light {
  @extend %pl-text;
  color: $color-gray-50;
}

%pl-help-text,
.pl-help-text {
  color: $color-gray-50;
  font-size: $font-xsmall;
}

%pl-help-text--below,
.pl-help-text--below {
  @extend %pl-help-text;
  display: block;
  padding-top: $layout-spacing-xsmall;
}

%pl-help-text--below--spaced,
.pl-help-text--below--spaced {
  @extend %pl-help-text--below;
  padding-top: $layout-spacing-small;
}

.pl-help-text--emphasis {
  @extend %pl-help-text;
  font-style: italic;
}

.pl-help-text--emphasis--below {
  @extend %pl-help-text--below;
  font-style: italic;
}

.pl-help-text--xxsmall {
  @extend %pl-help-text;
  @include font-xxsmall;
}

// ## Color classes
@each $label, $color in (
highlight: $color-highlight,
alert: $color-alert,
warn: $color-warn,
success: $color-success,
light: $color-content-light,
purple: $color-nbc-purple
) {
  .pl-color--#{$label} {
    color: $color !important;
    fill: $color !important;
  }
}

// ## Lead (a.k.a lede)
.pl-lead {
  @include font-large;
}

// ## Link
// Text link styles
%pl-link,
.pl-link {
  background: none;
  border: 0;
  color: $color-highlight;
  cursor: pointer;
  outline: none;
  padding: 0;
  text-decoration: underline;
  text-decoration-color: $color-gray-50;
  text-decoration-skip-ink: auto;
  text-decoration-style: dotted;
  text-decoration-thickness: 1px;
  text-underline-offset: 3px;
  transition: box-shadow .1s linear,padding .1s linear,margin .1s linear,background-color .1s linear;
  &:hover,
  &:focus {
    text-decoration-color: $color-highlight;
    text-decoration: underline;
  }
}

.pl-link--no-underline {
  @extend %pl-link;
  text-decoration: none;
}

.sn-table,
.pl-data-table {
  %pl-link,
  .sn-link,
  .pl-link {
    text-decoration: none;
  }
}

%pl-link--inverse,
.pl-link--inverse {
  @extend %pl-link;
  color: $bg-default;
  font-weight: 600;
}

.pl-link--help {
  @extend %pl-link;
  @include font-xxsmall;
  color: $color-content-xlight;
  text-decoration: underline;

  @include media-small {
    @include font-small;
  }
}

.pl-link--no-break {
  @extend %pl-link;
  display: inline-block;
}

.pl-link--no-break-inverse {
  @extend %pl-link--inverse;
  display: inline-block;
}

.pl-link--file-input {
  @extend %pl-link;

  input[type='file'] {
    left: -999in;
    position: absolute;
  }
}

.pl-quill--file-input-label {
  @extend %pl-link;

  color: #444;
  position: relative;
  top: 2.5px;
  &:hover {
    color: #06c;
  }
}

.selected {
  %pl-link--selected,
  .pl-link {
    color: $bg-default;
  }

  .pl-link--help,
  .pl-link--no-break {
    @extend %pl-link--selected;
  }
}

// ## CSS Multi-Column Control
@mixin pl-column-break-before {
  -webkit-column-break-before: always; // autoprefixer gets this wrong
  break-before: column;
}

@mixin pl-column-break-after {
  -webkit-column-break-after: always; // autoprefixer gets this wrong
  break-after: column;
}

@mixin pl-column-break-none {
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
  overflow: auto;
}

.pl-column-break-before {
  @include pl-column-break-before;
}

.pl-column-break-after {
  @include pl-column-break-after;
}

.pl-column-break-none {
  @include pl-column-break-none;
}

// for Internet Explorer 10 and 11 to show columns correctly in org/processor-settings
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .pl-column-break-none {
    @include pl-column-break-none;
    display: inline-block;
    width: 100%;
  }
}

.pl-ellipsis {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pl-keep-together {
  display: inline-block;
  margin: 0 !important;
}

.pl-preserve-newlines {
  white-space: pre-line;
}

.pl-nowrap {
  white-space: nowrap;
}

.pl-word-wrap {
  overflow-wrap: break-word;
}

// previously <hr>
$pl-divider-margin: .8571rem;
$pl-divider-margin-media-small: 1.4286rem;

%pl-divider {
  border: 0;
  border-top: solid 1px $color-content-xxlight;
}

.pl-divider {
  @extend %pl-divider;
  margin-bottom: $pl-divider-margin;
  margin-top: $pl-divider-margin;

  @include media-small {
    margin-bottom: $pl-divider-margin-media-small;
    margin-top: $pl-divider-margin-media-small;
  }
}

.pl-divider--no-margin {
  @extend %pl-divider;
}

.pl-divider--no-margin-top {
  @extend %pl-divider;
  margin-bottom: $pl-divider-margin;

  @include media-small {
    margin-bottom: $pl-divider-margin-media-small;
  }
}

.pl-divider--no-margin-bottom {
  @extend %pl-divider;
  margin-top: $pl-divider-margin;

  @include media-small {
    margin-top: $pl-divider-margin-media-small;
  }
}
