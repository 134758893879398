// 'chevron-down.svg' with $se-gray-700 (#68767f) fill
$select-icon-url: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' width='24' preserveAspectRatio='xMidYMid meet' aria-labelledby='title desc' role='img'%3E%3Ctitle id='title'%3Echevron-down%3C/title%3E%3Cpath fill='%2368767f' stroke-width='0' d='M19.4343146,7.43431458 C19.746734,7.12189514 20.253266,7.12189514 20.5656854,7.43431458 C20.8781049,7.74673401 20.8781049,8.25326599 20.5656854,8.56568542 L12.5656854,16.5656854 C12.253266,16.8781049 11.746734,16.8781049 11.4343146,16.5656854 L3.43431458,8.56568542 C3.12189514,8.25326599 3.12189514,7.74673401 3.43431458,7.43431458 C3.74673401,7.12189514 4.25326599,7.12189514 4.56568542,7.43431458 L12,14.8686292 L19.4343146,7.43431458 Z'%3E%3C/path%3E%3C/svg%3E";
$select-background-color: $se-white;
$select-icon-size: 14px;

// Spacing and sizing
$select-line-height: 18px;
$select-label-spacing: $se-spacing-300;
$select-vertical-padding: 8px;
$select-horizontal-padding: 12px;

$select-vertical-padding-spacious: 14px;
$select-horizontal-padding-spacious: 21px;

// Text colors
$select-error-color: $se-red;
$select-help-color: $se-gray-600;
$select-label-color: $se-gray-700;
$select-optional-color: $se-gray-600;
$select-placeholder-color: $se-gray;

// Border
$select-border-radius: 4px;
$select-border-width: 1px;

// Border colors
$select-border-color: $se-gray;
$select-border-error-color: $se-red;
$select-border-focus-color: $se-blue;
$select-border-hover-color: $se-gray-600;

// Box Shadow
@function select-box-shadow($color) {
  @return 0 0 0 1px $color;
}

// Font sizes
$select-input-font-size: $se-font-size-500;
$select-label-font-size: $se-font-size-500;
$select-optional-font-size: $se-font-size-400;
$select-help-font-size: $se-font-size-400;
$select-error-font-size: $se-font-size-400;

%pl-shim-form-control-select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: $select-background-color;
  background-image: url($select-icon-url);
  background-repeat: no-repeat;
  background-size: $select-icon-size $select-icon-size;
  border-radius: $select-border-radius;
  border-style: solid;
  border-width: $select-border-width;
  cursor: pointer;
  font-size: $select-input-font-size;
  line-height: $select-line-height;
  width: 100%;
  border-color: $select-border-color;

  &:hover {
    border-color: $select-border-hover-color;
  }

  &:focus {
    border-color: $select-border-focus-color;
    box-shadow: select-box-shadow($select-border-focus-color);
  }

  &.has-errors {
    border-color: $select-border-error-color;

    &:focus {
      box-shadow: select-box-shadow($select-border-error-color);
    }
  }
}

.pl-shim-form-control-select {
  @extend %pl-shim-form-control-select;
  background-position: right $select-horizontal-padding top 50%;
  padding: $select-vertical-padding $select-horizontal-padding;
}

.pl-shim-form-control-select--spacious {
  @extend %pl-shim-form-control-select;
  background-position: right $select-horizontal-padding-spacious top 50%;
  padding: $select-vertical-padding-spacious $select-horizontal-padding-spacious;
}
