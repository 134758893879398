%se-button--low {

  $background:      transparent;
  $color:           $se-button-base-color;
  $disabled-color:  $se-gray-500;
  $active-bg:       rgba($color, .16);
  $focus-bg:        rgba($color, .12);
  $hover-bg:        rgba($color, .04);
  $loading-bg:      $focus-bg;

  background: $background;
  color: $color;

  &:hover {
    background: $hover-bg;
  }

  &:focus {
    background: $focus-bg;
  }

  &:active {
    background: $active-bg;
  }

  &.is-loading {
    background: $loading-bg;
  }

  &:disabled {
    background: $background;
    color: $disabled-color;
  }

}
