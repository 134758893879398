$user-service-subheader-padding-top: 2px;

.pl-heading--large {
    font-weight: $font-semibold;

    & + .pl-subheading {
        padding-top: $user-service-subheader-padding-top;
    }
}

.pl-subheading {
    color: $se-gray-900;
}


