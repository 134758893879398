// # Typography Tags (not recommended but retained for legacy modules)
// Default styles for headings, body copy, lists, etc.

// ## Formatting
blockquote {
  @extend %content-spacing;
  border-left: solid .2857rem $color-content-xxlight; // 4px / 14px
  color: $color-content-light;
  margin-left: 0;
  margin-right: 0;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0; // remove and let class styles override
}

small {
  @include font-xsmall;
}

mark {
  background-color: $color-highlight-light;
}

code,
kbd,
samp,
pre {
  background-color: $color-content-xxxlight;
  padding: .1429rem .2857rem; // 2px / 14px, 4px / 14px
}

pre {
  padding: .8571rem; // 12px / 14px
}
