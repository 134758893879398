// # Layout
// Page layout

// .pl-app
//   .pl-app__loader
//   .pl-app__header
//   .pl-app__body
//     .pl-app__nav
//     .pl-app__content

// iOS has trouble scrolling iframe content, so we scroll the .pl-app--content instead of html/window at phone
html.has-pl-app,
html.has-pl-app body {
  height: 100%;
  overflow: hidden;
  // iOS has a bug where everything dissapears. Prespective and transform help
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  width: 100%;
}

.pl-app {
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  position: absolute; // was fixed, but it broke se-ui left nav z-index
  top: 0;
  width: 100%;

  &__loader {
    flex: 0 0 100%;
    position: relative;
  }

  &__header {
    flex: 0 0 auto;
    position: relative;
  }

  &__body {
    align-items: stretch;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: auto; // enforces flex dimensions
    position: relative;
  }

  &__side-nav {
    flex: 0 0 auto;
    position: relative;
  }

  &__content {
    #{'-webkit-overflow-scrolling'}: touch;
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
  }
}


// ## Gray background for apps like Payments
.pl-layout--on-gray {
  background: $gray-xlight !important;
}
.pl-layout--on-gray-xxlight {
  background: $gray-xxlight !important;
}

.pl-layout--full-height {
  height: 100%;
}


// ## Content container
.pl-page-content {
  // Offset content on desktop to make room when side-nav is present
  .pl-has-side-nav & {
    @media (min-width: $media-small + $side-nav-width) {
      margin-left: $side-nav-width;
    }
  }
}


// ## Centered container


%pl-centered {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  @include clear-after;
}

%pl-centered-content {
  @extend %pl-centered;
  padding-left: $layout-spacing-large;
  padding-right: $layout-spacing-large;
}

%pl-centered-container {
  @extend %pl-centered;
  @include media-small {
    padding-left: $layout-spacing-large;
    padding-right: $layout-spacing-large;
  }
}

.pl-centered-content {
  @extend %pl-centered-content;
  width: $layout-width;
}

.pl-centered-content--small {
  @extend %pl-centered-content;
  width: $layout-width-small;
}

.pl-centered-content--large {
  @extend %pl-centered-content;
  width: $layout-width-large;
}

.pl-centered-content--auto {
  @extend %pl-centered-content;
  width: auto !important;
}

.pl-centered-container {
  @extend %pl-centered-container;
  width: $layout-width;
}

.pl-centered-container--small {
  @extend %pl-centered-container;
  width: $layout-width-small;
}

.pl-centered-container--large {
  @extend %pl-centered-container;
  width: $layout-width-large;
}

.pl-centered-container--auto {
  @extend %pl-centered-container;
  width: auto !important;
}

.pl-centered-container--max-width {
  @extend %pl-centered;
  width: $container-max-width;
}

.pl-centered-container--max-width-medium {
  @extend %pl-centered;
  width: 750px;
}

// ## Centered section
%centered-section {
  margin-bottom: $layout-spacing-large;
  margin-top: $layout-spacing-large;
}

.pl-centered-section {
  @extend %centered-section;
  background: #fff;
  border-color: $gray-light;
  border-style: solid;
  border-width: 1px 0;
  // Add left and right borders at larger window widths
  @include media-small {
    border-radius: 2px;
    border-width: 1px;
  }
}


// ## Subsection
.pl-centered-section + .pl-subsection {
  margin-top: -$layout-spacing-large;
}

%pl-subsection-base {
  display: block;
}

%pl-subsection-padding {
  padding: $layout-spacing-large $layout-spacing-medium;
  @include media-small {
    padding: $layout-spacing-large;
  }
}

%pl-subsection-padding-condensed {
  padding: $layout-spacing-small $layout-spacing-large;
}

%pl-subsection-background-gray-xxlight {
  background: $gray-xxlight;
}

%pl-subsection-background-color-highlight-light {
  background: $color-highlight-light;
}

%pl-subsection-indented {
  padding: $layout-spacing-large ($layout-spacing-large * 2);
}

%pl-subsection-border {
  &:not(:last-child) {
    border-bottom: 1px solid $gray-light;
  }
}

%pl-subsection-border-before {
  &:not(:first-child) {
    border-top: 1px solid $gray-light;
  }
}

%pl-subsection-divider {
  background: $gray-light;
  content: '';
  display: block;
  height: 1px;
  position: relative;
}

%pl-prevent-duplicate-border {
  [class^='pl-subsection'][class*='-border']:not([class*='-border-before']) + &,
  [class^='pl-subsection'][class*='-divider']:not([class*='-divider-before']) + & {
    border-top: 0;
  }
}

%pl-prevent-duplicate-divider {
  [class^='pl-subsection'][class*='-border']:not([class*='-border-before']) + &::before,
  [class^='pl-subsection'][class*='-divider']:not([class*='-divider-before']) + &::before {
    visibility: hidden;
  }
}

.pl-subsection {
  @extend %pl-subsection-base;
  @extend %pl-subsection-padding;
}

.pl-subsection--indented {
  @extend %pl-subsection-base;
  @extend %pl-subsection-indented;
}

.pl-subsection--border {
  @extend %pl-subsection-base;
  @extend %pl-subsection-padding;
  @extend %pl-subsection-border;
}

.pl-subsection--border-before {
  @extend %pl-subsection-base;
  @extend %pl-subsection-padding;
  @extend %pl-subsection-border-before;
  @extend %pl-prevent-duplicate-border;
}

.pl-subsection--divider {
  @extend %pl-subsection-base;
  @extend %pl-subsection-padding;

  &:not(:last-child)::after {
    @extend %pl-subsection-divider;
    bottom: -$layout-spacing-medium;
    width: 100%;
    @include media-small {
      bottom: -$layout-spacing-large;
    }
  }
}

.pl-subsection--divider-indented {
  @extend %pl-subsection-base;
  @extend %pl-subsection-indented;

  &:not(:last-child)::after {
    @extend %pl-subsection-divider;
    bottom: -$layout-spacing-medium;
    left: -$layout-spacing-large;
    width: calc(100% + #{$layout-spacing-large * 2});
    @include media-small {
      bottom: -$layout-spacing-large;
    }
  }
}

.pl-subsection--divider-before {
  @extend %pl-subsection-base;
  @extend %pl-subsection-padding;
  @extend %pl-prevent-duplicate-divider;

  &:not(:first-child)::before {
    @extend %pl-subsection-divider;
    top: -$layout-spacing-medium;
    width: 100%;
    @include media-small {
      top: -$layout-spacing-large;
    }
  }
}

.pl-subsection--divider-before-indented {
  @extend %pl-subsection-base;
  @extend %pl-subsection-indented;
  @extend %pl-prevent-duplicate-divider;

  &:not(:last-child)::before {
    @extend %pl-subsection-divider;
    bottom: -$layout-spacing-medium;
    width: calc(100% + #{$layout-spacing-large * 2});
    @include media-small {
      bottom: -$layout-spacing-large;
    }
  }
}

.pl-subsection--background, // deprecated
.pl-subsection--background-border {
  @extend %pl-subsection-base;
  @extend %pl-subsection-padding;
  @extend %pl-subsection-background-gray-xxlight;
  @extend %pl-subsection-border;

  &::before {
    @extend %pl-subsection-divider;
    left: 0;
    position: absolute;
    right: 0;
    top: -1px;
  }
}

.pl-subsection--background-condensed, // deprecated
.pl-subsection--background-border-condensed {
  @extend %pl-subsection-base;
  @extend %pl-subsection-padding-condensed;
  @extend %pl-subsection-background-gray-xxlight;
  @extend %pl-subsection-border;

  &::before {
    @extend %pl-subsection-divider;
    left: 0;
    position: absolute;
    right: 0;
    top: -1px;
  }
}

.pl-subsection--background-border-indented {
  @extend %pl-subsection-base;
  @extend %pl-subsection-indented;
  @extend %pl-subsection-background-gray-xxlight;
}

.pl-subsection--background-highlight {
  @extend %pl-subsection-base;
  @extend %pl-subsection-padding;
  @extend %pl-subsection-background-color-highlight-light;
}

.pl-subsection--background-highlight-condensed {
  @extend %pl-subsection-base;
  @extend %pl-subsection-padding-condensed;
  @extend %pl-subsection-background-color-highlight-light;
}

.pl-subsection--background-highlight-indented {
  @extend %pl-subsection-base;
  @extend %pl-subsection-indented;
  @extend %pl-subsection-background-color-highlight-light;
}


// ## Split containers
$split-half-width: calc(50% - .5em);
.pl-split__container {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.pl-split--full {
  width: 100%;
}
.pl-split--half,
%split--half {
  width: $split-half-width;
}
.pl-split--half\@small {
  @include media-small {
    width: $split-half-width;
  }
}

@for $i from 1 through 12 {
  $multiplier: $i / 12;
  $grid-gutter: .5em;
  $split-width: calc(100% * #{$multiplier} - #{$grid-gutter} * (1 - #{$multiplier}));
  .pl-split--#{$i}of12 {
    width: $split-width;
  }
  .pl-split--#{$i}of12\@small {
    @include media-small {
      width: $split-width;
    }
  }
}


// ## Divider (horizontal rule)
.pl-horizontal-rule {
  background-color: $gray-light;
  border: 0;
  display: block;
  height: 1px;
  margin-bottom: .8571rem;
  margin-top: .8571rem;

  @include media-small {
    margin-bottom: 1.4286rem;
    margin-top: 1.4286rem;
  }
}

.pl-content--vertical-centered {
  align-items: center;
  display: flex;
}

.pl-content--vertical-bottom {
  align-items: flex-end;
  display: flex;
}
