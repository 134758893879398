// # Fonts
@import 'functions'; // add join-list

$font-face-list: () !default;

// Use this to keep track of the fonts you use...
@mixin font-face($family, $style, $weight, $src) {
  @font-face {
    font-family: $family;
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
  // Hack to store all font faces as a string and expose to JS
  $font-face-list: append($font-face-list, '{"font-family":"#{$family}","font-style":"#{$style}","font-weight":"#{$weight}"}') !global;
}

// Then use this to expose those fonts to JS! You can get the exposed string,
// which you will need to parse as json, by doing something like this:
// var fonts = JSON.parse($('<font-face-list/>').css('fontFamily').replace(/\\/g,'').replace(/^"|"$/g, ''))
@mixin expose-font-face-list {
  font-face-list {
    font-family: '#{join-list($font-face-list, ', ', '[', ']')}';
    left: -999in;
    opacity: 0;
    position: absolute;
  }
}

// ## Size
// Mixin that sets font-size with the correct line-height

@mixin font-xxsmall {
  font-size: $font-xxsmall;
  line-height: $font-leading-small;
}
@mixin font-xsmall {
  font-size: $font-xsmall;
  line-height: $font-leading-small;
}
@mixin font-small {
  font-size: $font-small;
  line-height: $font-leading-body;
}
@mixin font-medium {
  font-size: $font-medium;
  line-height: $font-leading-subheading;
}
@mixin font-large {
  font-size: $font-large;
  line-height: $font-leading-title;
}
@mixin font-xlarge {
  font-size: $font-xlarge;
  line-height: $font-leading-headline;
}
@mixin font-xxlarge {
  font-size: $font-xxlarge;
  line-height: $font-leading-display;
}
@mixin font-xxxlarge {
  font-size: $font-xxxlarge;
  line-height: $font-leading-display-large;
}
