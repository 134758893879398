// Adds (condense|collapse)-(top|bottom|left|right|x|y) padding modifiers to a base class.

// Switch @extend #{$base-placeholder-class} to @extend %#{$base-placeholder-class} once this is fixed:
// https://github.com/sasstools/sass-lint/issues/782

@mixin padding-modifiers($base-selector, $base-placeholder-class: null) {
  @each $behavior, $spacing in (collapse: 0, condense: $layout-spacing-medium) {
    #{$base-selector}#{'--'}#{$behavior} {
      @if $base-placeholder-class {
        @extend #{$base-placeholder-class};
      }
      padding: $spacing;
    }

    // Change #{'-'} to - below after fix: https://github.com/sasstools/sass-lint/issues/644
    @each $side, $dir in (left: 'x', right: 'x', top: 'y', bottom: 'y') {
      #{$base-selector}#{'--'}#{$behavior}#{'-'}#{$dir},
      #{$base-selector}#{'--'}#{$behavior}#{'-'}#{$side} {
        @if $base-placeholder-class {
          @extend #{$base-placeholder-class};
        }
        #{'padding-'}#{$side}: $spacing;
      }
    }
  }
}
