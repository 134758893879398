$pl-pane-padding-mobile: $layout-spacing-medium 16px;

@keyframes panel-from-right {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes panel-from-center {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes panel-from-bottom {
  0% {
    opacity: 0;
    transform: scale(0);
    transform-origin: bottom;
  }
  100% {
    opacity: 1;
    transform: scale(1);
    transform-origin: bottom;
  }
}

// ## Split panels
%pl-pane__container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

// .pl-pane-collapse removes border totally
.pl-pane__container--vertical {
  @extend %pl-pane__container;
  @include flex-box(row);
  > *:not(:last-child):not(.pl-pane--collapse) {
    border-right: 1px solid $gray-light;
  }
  > .pl-pane__container--no-border {
    border: 0 !important;
  }
}

.pl-pane__container--horizontal {
  @extend %pl-pane__container;
  @include flex-box(column);
}

// ## Panes
%pl-pane {
  @include flex(1, 1000, 100%);
  background: $bg-default;
  overflow-x: hidden;
  overflow-y: auto;
  padding: $pl-pane-padding-mobile;
  position: relative;
  // -webkit-overflow-scrolling: touch; // this messes up fullscreen modals, but is necessary for tablet...?
  @media (min-width: $tablet-width) {
    padding: $layout-spacing-large;
  }
}

.pl-pane {
  @extend %pl-pane;
}

.pl-pane--border {
  @extend %pl-pane;
  &:not(:last-child) {
    border-bottom: 1px solid $gray-light;
  }
}

.pl-pane--border-above {
  @extend %pl-pane;
  &:not(:first-child) {
    border-top: 1px solid $gray-light;
  }
}

.pl-pane--collapse {
  @extend %pl-pane;
  border: 0;
}

.pl-pane--shrink {
  @extend %pl-pane;
  @include flex(0);
  overflow: visible;
}

.pl-pane--greedy {
  @extend %pl-pane;
  @include flex(0, 1, auto);
}

.pl-pane--overflow {
  // @todo .sn-overflow-pane
  @extend %pl-pane;
  overflow: visible;
}

.pl-pane--shadow {
  @extend %pl-pane;
  box-shadow: 0 1px 2px #ccc;
}

.pl-pane--no-scroll {
  overflow: hidden !important;
}

.pl-pane__inner-scroller {
  bottom: $layout-spacing-large;
  left: $layout-spacing-large;
  overflow: auto;
  position: absolute;
  right: $layout-spacing-large;
  top: $layout-spacing-large;
  z-index: 0;

  // hack to size our small avatars before initialization so they don't break sticky columns
  [class*='-avatar'][se-data-size='100'] {
    height: 24px;
    width: 24px;
  }

  .pl-pane--collapse > &,
  .pl-pane--collapse-y > &,
  .pl-pane--collapse-top > & {
    top: 0;
  }

  .pl-pane--collapse > &,
  .pl-pane--collapse-y > &,
  .pl-pane--collapse-bottom > & {
    bottom: 0;
  }

  .pl-pane--collapse > &,
  .pl-pane--collapse-x > &,
  .pl-pane--collapse-right > & {
    right: 0;
  }

  .pl-pane--collapse > &,
  .pl-pane--collapse-x > &,
  .pl-pane--collapse-left > & {
    left: 0;
  }
}

%pl-pane--full-screen-no-nav {
  animation: panel-from-center $duration-slow ease-in-out;
  background: #fff;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: $ngin-bar-height;
  z-index: $ngin-bar-z - 5;
}

.pl-pane--full-screen-no-nav {
  @extend %pl-pane--full-screen-no-nav;
}

.pl-pane--full-screen {
  @extend %pl-pane--full-screen-no-nav;
  @media (min-width: $media-desktop) {
    left: $side-nav-width;
  }
}

.pl-pane--medium,
.pl-pane--large {
  animation: panel-from-right $duration-slow ease-in-out;
  border-color: $gray-light;
  border-style: solid;
  z-index: $z-popup;
}

.pl-pane--medium {
  border-width: 0 0 0 1px;
  left: auto;
  right: 0;
  width: 66.6666666666%;

}

.pl-pane--large {
  border-width: 1px;
  bottom: 0;
  left: $layout-spacing-large;
  right: $layout-spacing-large;
  top: $layout-spacing-large;

  .pl-pane__save-bar {
    background: $bg-default;
    box-sizing: content-box;
    padding-left: $layout-spacing-large + 1;
    padding-right: $layout-spacing-large + 1;
  }
}

@include padding-modifiers('.pl-pane', '%pl-pane');

@for $i from 0 through 12 {
  .pl-pane--#{$i} {
    @extend %pl-pane;
    @include flex(0, 0, 100% * $i / 12);
    transition: all $duration;

    @if $i == 0 {
      border-width: 0;
      opacity: 0;
      padding: 0;
    }
  }
}

%pl-pane__info,
.pl-pane__info {
  @extend %pl-pane;
  background: $gray-xxlight;
  border-bottom: 1px solid $gray-light;
  color: $color-content;
}

// 976 max content width (1024 - 24 * 2)
%pl-pane__info--large {
  @extend %pl-pane__info;
  @media (max-width: $tablet-width - 1) {
    overflow: visible;
    padding: .05px 0; // prevent margin collapse

    // When panes loose their absolute positioning (to be responsive) the info pane (and background color)
    // do not extend to the bottom. This adjusment adds a pseudo element fixed bosition to re-color the background
    // when we have content that is less than the screen height.
    &::after {
      background: $gray-xxlight;
      content: '';
      display: block;
      height: 100vh;
      margin-bottom: -100vh;
      position: fixed;
      width: 100vw;
      z-index: -1;
    }
  }
}

.pl-pane__info--large {
  @extend %pl-pane__info--large;

  // The breakpoint where we start restricting width assumes there is a sidebar visible.
  // We default the value here to prevent compile errors in themes that may use the panes but not the sidebar.
  $side-nav-width: 218px !default;
  $max-padding: 488px - ($side-nav-width / 2);

  // adjust max width when side nav is present
  @media (min-width: #{1025px + $side-nav-width}) {
    padding-left: calc(50% - 488px);
    padding-right: calc(50% - 488px);
  }
}

// This is like `.pl-pane__info--large` but without accomidations for a sidebar
.pl-pane__info--full-screen {
  @extend %pl-pane__info--large;

  @media (min-width: 1025px) {
    padding-left: calc(50% - 488px);
    padding-right: calc(50% - 488px);
  }
}

.pl-pane__nav {
  @extend %pl-pane;
  @include flex(0);
  background: $gray-xxlight;
  border-bottom: 1px solid $gray-light;
  color: $color-content;
  padding-bottom: $layout-spacing-small / 2;
  padding-top: $layout-spacing-small / 2;
}

.pl-pane__header {
  @extend %pl-pane;
  @include flex(0, 0, auto);
  border-bottom: 1px solid $gray-light;

  &-actions {
    @media (min-width: $media-small) {
      margin-top: -52px;
      position: absolute;
      right: 0;
      text-align: right;
      top: 0;
    }
  }

  .pl-tabs {
    border-bottom: 0;
  }
}

.pl-pane__two-columns {
  @extend .pl-pane__container--horizontal;
  max-width: 1024px;
}

.pl-pane__save-bar {
  @extend .pl-pane--shrink;
  border-top: 1px solid $gray-light;
  padding: $layout-spacing-large;
}

.pl-show-extra {
  @extend .pl-pane--shrink;
  background-color: $gray-xxlight;
  border-bottom: 1px solid $gray-light;
  border-top: 1px solid $gray-light;
  cursor: pointer;
  padding-bottom: $layout-spacing-medium;
  padding-top: $layout-spacing-medium;
  &.is-active {
    svg {
      transform: rotate(-180deg);
      transition: transform $duration;
    }
  }
}

.pl-pane__event {
  margin-bottom: 25px;
  .pl-pane__header {
    border-bottom: 2px solid $color-highlight;
  }
}

.pl-pane-identity-header {
  &__title {
    font-size: $font-xlarge;
    line-height: $font-leading-subheading;
    margin-top: -$layout-spacing-xsmall;
  }

  &__sub-title {
    color: $color-gray-50;
    font-weight: $font-regular;
  }
}

// This is used to circumvent the declaration of %remove-pane-layout in temporary-mobile-overrides
// to correctly display mobile in iframes (using display: flex)
.pl-iframe-pane-container {
  @extend %pl-pane__container;
  @include flex-box(column);
  height: calc((var(--vh, 1vh) * 100) - #{$ngin-bar-height});
}
