// # Close
// "x" close button for alerts and overlays


// ## Base close button styles
$close-icon-size: 1.215rem; // 17px / 14px; was also used for line-height in &::before, but cuts off close button in IE 11
.pl-close,
%pl-close {
  background: transparent;
  border: 0;
  color: currentColor;
  height: 0;
  overflow: hidden;
  padding: $close-icon-size 0 0;
  position: relative;
  width: $close-icon-size;

  &::before {
    content: '\2715';
    font-size: 1rem;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: scale($close-icon-size / 1rem * 1.05);
  }
}
