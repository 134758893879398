// Use the same value everywhere the height of the ngin-bar is referenced.

$ngin-bar-height: 60px;
$ngin-bar-z: 98;
$nb-options-caret-size: 7px;
$nb-mobile-caret-offset: 32px;

:root {
  --vh: 1vh; // used to help iOS since vh units are lazily evaluated (dafault overridden with JS)
  --se-bar-top: 0px; // used to align things when the se bar is pushed down due to alert content (dafault overridden with JS) (px required by iOS safari)
}
