// Spacing and sizing
$form-control-padding: 8px 12px;
$form-control-padding-comfy: 14px 21px;
$form-control-line-height: 18px;

// Text colors
$form-control-error-color: $se-alert;
$form-control-label-color: $se-gray-700;
$form-control-optional-color: $se-gray-600;
$form-control-placeholder-color: $se-gray;

// Border
$form-control-border-radius: 4px;
$form-control-border-width: 1px;

// Border colors
$form-control-border-color: $se-gray;

$form-control-border-color-hover: $se-gray-600;

$form-control-border-color-error: $se-alert;
$form-control-border-color-error-darkened: $se-alert-600;

$form-control-border-color-focus: $se-highlight;
$form-control-border-color-focus-darkened: $se-highlight-600;

// Font sizes
$form-control-input-font-size: $se-font-size-500;
$form-control-label-font-size: $se-font-size-500;
$form-control-optional-font-size: $se-font-size-400;
$form-control-error-font-size: $se-font-size-400;


%se-form-control-states {
  border-color: $form-control-border-color;

  &:hover {
    border-color: $form-control-border-color-hover;
  }

  &:focus {
    border-color: $form-control-border-color-focus;
    box-shadow: 0 0 0 1px $form-control-border-color-focus;

    &:hover {
      border-color: $form-control-border-color-focus-darkened;
      box-shadow: 0 0 0 1px $form-control-border-color-focus-darkened;
    }
  }
}

%se-form-control-states--error {
  border-color: $form-control-border-color-error;

  &:hover {
    border-color: $form-control-border-color-error-darkened;
  }

  &:focus {
    border-color: $form-control-border-color-error;
    box-shadow: 0 0 0 1px $form-control-border-color-error;

    &:hover {
      border-color: $form-control-border-color-error-darkened;
      box-shadow: 0 0 0 1px $form-control-border-color-error-darkened;
    }
  }
}

%pl-shim-form-control-text {
  border-radius: $form-control-border-radius;
  border-style: solid;
  border-width: $form-control-border-width;
  font-size: $form-control-input-font-size;
  line-height: $form-control-line-height;
  width: 100%;

  &::placeholder {
    color: $form-control-placeholder-color;
  }

  @extend %se-form-control-states;

  &.has-errors {
    @extend %se-form-control-states--error;
  }
}

.pl-shim-form-control-text {
  @extend %pl-shim-form-control-text;
  padding: $form-control-padding;
}

.pl-shim-form-control-text--spacious {
  @extend %pl-shim-form-control-text;
  padding: $form-control-padding-comfy;
}