$pl-skeleton-shine-duration: 1s;
$pl-skeleton-shine-opacity: .3;

@keyframes pl-skeleton-shine {

  0% {
    background-position: 150%;
  }

  40% {
    background-position: -100%;
  }

  100% {
    background-position: -100%;
  }

}

@mixin pl-skeleton-text($color) {
  @extend %pl-skeleton-object;
  @include pl-skeleton-shine($color);
  height: .7em;
  margin: .25em 0;
}

@mixin pl-skeleton-button($color) {
  @extend %pl-skeleton-object;
  @include pl-skeleton-shine($color);
  border-radius: 4px;
  height: 1.2em;
  margin: 0;
}

@mixin pl-skeleton-shine($color) {
  background-color: rgba($color, $pl-skeleton-shine-opacity);
  background-image: linear-gradient(90deg, rgba($color, 0) 0, rgba($color, $pl-skeleton-shine-opacity) 25%, rgba($color, 0) 75%);
}

%pl-skeleton-object {
  animation: pl-skeleton-shine $pl-skeleton-shine-duration infinite linear;
  background-repeat: no-repeat;
  background-size: 200%;
  border-radius: .35em;
  content: '';
  display: block;
  max-width: 100%;
  width: 100%;
}
