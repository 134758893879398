// # Option
// Platform styled radio buttons and checkboxes

.pl-option,
%pl-option {
  display: block;

  & + & {
    margin-top: $option-spacing;
  }
}

.pl-option--descriptive {
  @extend %pl-option;

  $input-offset: $option-input-size + $option-spacing;
  padding-left: $input-offset;

  > .pl-option__label,
  > .pl-option__label--emphasis {
    margin-left: -$input-offset;
  }

  .pl-option__help-text {
    @include font-small;
    color: $option-help-text-color;
    margin-top: $option-help-text-margin-top;
  }
}

.pl-option--horizontal {
  display: inline-block;
  margin-bottom: $option-horizontal-margin-bottom;
  margin-right: $option-horizontal-margin-right;

  &:last-child {
    margin-bottom: 0;
    margin-right: 0;
  }
}

.pl-option__input {
  left: -9999px;
  position: absolute;
}

.pl-option-group {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  > .pl-option {
    align-items: center;
    display: flex;
    height: $option-group-item-height;
  }
}

// Shared silent class for visual display of labels (no positioning styles here please)
%pl-option-label {
  color: $option-label-color;
  position: relative;

  // Checked state
  :checked + & {
    color: $option-label-checked-color;
  }

  // Hover state
  :not([disabled]) + &:hover {
    color: $option-label-hover-color;
  }

  // Focus state
  :focus + &:not(:hover) {
    outline: $option-accessibility-outline;
    z-index: $z-option--focus;
  }

  // Disabled state
  [disabled] + & {
    border-color: $option-disabled-border-color;
    color: $option-disabled-color;
    cursor: default;
  }

  &::before {
    background-color: #fff;
    background-position: center;
    border: $option-input-border;
    content: '';
    display: inline-block;
    height: $option-input-size;
    min-height: $option-input-size;
    min-width: $option-input-size;
    width: $option-input-size;

    [type='checkbox'] + & {
      border-radius: $option-checkbox-border-radius;
    }

    [type='checkbox']:disabled + & {
      border-color: $option-disabled-color;
    }

    [type='radio'] + & {
      border-radius: $option-radio-border-radius;
    }

    :checked + & {
      background-repeat: no-repeat;
      border-color: $option-input-checked-border-color;
    }

    :checked[type='checkbox'] + & {
      background-image: $option-input-checkbox-checked-background-image;
    }

    :checked[type='checkbox']:disabled + & {
      background-image: $option-input-disabled-checkbox-checked-background-image;
      border-color: $option-disabled-color;
    }

    :indeterminate[type='checkbox'] + & {
      background-image: $option-input-checkbox-indeterminate-background-image;
    }

    :checked[type='radio'] + & {
      background-image: $option-input-radio-checked-background-image;
    }
  }
}


%pl-option__label,
.pl-option__label {
  @extend %pl-option-label;
  display: block;
  min-height: $font-leading-body * 1rem;
  padding-left: $option-spacing + $option-input-size;

  &::before {
    left: 0;
    position: absolute;
    top: calc(.6665em - #{$option-input-size / 2}); // half line height minus half icon height
    vertical-align: -12%;
  }
}

.pl-option__label--emphasis {
  @extend %pl-option__label;
  color: $color-content;
  font-size: $font-large;
  font-weight: $font-bold;
}

.pl-option__label--with-inputs {
  @extend %pl-option-label;
  display: block;
  line-height: $option-label-with-inputs-line-height;
  padding-left: $option-spacing + $option-input-size;

  &::before {
    margin-left: -($option-spacing + $option-input-size);
    margin-right: $option-spacing;
    vertical-align: middle;
  }

  .pl-addon-field,
  .pl-field,
  .pl-select {

    &:not(:first-child) {
      margin-left: $layout-spacing-small;
    }
    &:not(:last-child) {
      margin-right: $layout-spacing-small;
    }
  }

  > .pl-addon-field {
    vertical-align: top; // fixes baseline alignment related to overflow hidden
  }
}

.pl-option--readonly {
  @extend %pl-option;

  $no-icon: 'data:image/svg+xml;utf-8,<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path fill="rgba(35,40,43,.3)" d="M11.164 11.87a5 5 0 0 1-7.036-7.036l7.036 7.037zm.707-.706a5 5 0 0 0-7.036-7.036l7.037 7.036zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" fill-rule="evenodd"/></svg>';
  $checkmark-icon: 'data:image/svg+xml;utf-8,<svg viewBox="0 0 11 8" xmlns="http://www.w3.org/2000/svg"><path d="M9.6.293S4.626 5.52 4.512 5.405L2.1 2.993.793 4.3 4.5 8.007 10.907 1.6 9.6.293z" fill="#13AA37"/></svg>';

  color: $option-readonly-color;
  display: block;
  min-height: $font-leading-body * 1rem; // handle options with empty text
  padding-left: $option-spacing + $option-input-size;
  position: relative;

  &::before {
    background-image: url($no-icon);
    background-position: center;
    background-repeat: no-repeat;
    content: '';
    display: inline-block;
    height: $option-input-size;
    left: 0;
    margin: $option-border-size; // maintain same position as active option control, with a border
    position: absolute;
    top: calc(.6665em - #{$option-input-size / 2}); // half line height minus half icon height
    width: $option-input-size;
  }

  &.pl-checked {
    color: $option-readonly-checked-color;

    &::before {
      background-image: url($checkmark-icon);
    }
  }
}
