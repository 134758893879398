@media (max-width: $media-small) {

  $mobile-input-size: 44px;
  $spinner-spacing: 50px;

  %remove-pane-layout {
    #{'-webkit-overflow-scrolling'}: auto !important;
    display: block !important;
    overflow: visible !important;
    position: relative;
  }

  // GLOBAL SCROLL

  html:not(.pl-has-app) {
    height: 100%;
    overflow: auto;

    body {
      height: auto;
      overflow: visible;
      // prevent double scrollbars when modal is open
      &.ngdialog-open {
        max-height: 100%;
        overflow: hidden;
        position: absolute;
      }
    }
  }

  // PANES / CONTAINERS

  .pl-pane,
  [class*='pl-pane--'],
  [class*='pl-pane__container'],
  [class*='sn-pane'],
  [class*='sn-split'] {
    &:not(.pl-pane--full-screen):not(.pl-pane--medium):not(.pl-modal__content):not(.sn-side-nav):not(.sn-pane-inner-scroller):not(.pl-side-nav--popover):not(.pl-side-nav) {
      @extend %remove-pane-layout;
    }
  }

  // INNER SCROLLER

  .sn-no-scroll-pane,
  .pl-pane--no-scroll {
    padding: 0 !important;
  }

  .sn-pane-inner-scroller,
  .pl-pane__inner-scroller {
    #{'-webkit-overflow-scrolling'}: touch !important;
    bottom: auto !important;
    left: auto !important;
    overflow: auto !important;
    position: relative !important;
    right: auto !important;
    top: auto !important;
  }

  // SIDE NAV POPOVER

  .pl-side-nav:not(.pl-side-nav--popover) {
    width: 100%;
  }

  // NGIN BAR

  .nb-container {
    left: 0 !important;
    position: fixed !important;
    top: 0 !important;
    width: 100% !important;
  }

  // FULL SCREEN PANES

  .pl-pane--full-screen {
    #{'-webkit-overflow-scrolling'}: touch !important;
    overflow: auto !important;
  }

  // STICKY HEADER PANES

  .pl-pane--sticky-top {
    position: sticky !important;
    top: 0;
    z-index: 90;
  }

  // SECTION SPINNERS

  .pl-spinner {
    margin: $spinner-spacing 0 !important;
    position: static !important;
  }

  // PAGINATION

  .sn-pagination {
    .sn-row,
    .sn-col {
      display: block !important;
      float: none !important;

      &::before,
      &::after {
        display: none !important; // hide clear fix
      }
    }

    .sn-pagination__field {
      height: $mobile-input-size !important;
      margin-right: 4px !important;
      min-width: $mobile-input-size !important;
    }

    .sn-pagination__options {
      color: $color-content-light !important;
      padding-bottom: 6px;
      text-align: center !important;
    }

    .sn-pagination__controls {
      // per page
      .sn-col:first-child {
        display: none !important;
      }
      // controls
      .sn-col:last-child {
        clear: both !important;
        display: block !important;
        left: 6px !important;
        padding: 0 !important;
        position: relative !important;
      }
    }

    // Make pagination controlls full width;
    .sn-input-group {
      width: 100% !important;

      button {
        height: $mobile-input-size !important;
        width: 100% !important;
      }
    }

    slider,
    slider + .sn-input-group-spacing {
      display: none !important;
    }
  }

  // FORM ACTIONS

  // This seems to only be used for "required" ATM. If this changes, remove this.
  .pl-form-actions__info {
    display: none !important;
  }

  // MODALS

  .pl-modal,
  [class*='pl-modal--'] {
    border: 0;
    border-radius: 0;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    position: fixed;
    top: 0;
    width: 100%;
  }

  // FULL PAGE IFRAME (myse)
  .pl-iframe--full {
    min-height: calc(100vh - #{$ngin-bar-height});
    overflow: hidden; // JS resizes this
  }

}
