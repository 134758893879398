%se-button--high {

  $background:      $se-button-base-color;
  $color:           $se-white;
  $disabled-bg:     $se-gray-400;
  $active-bg:       rgba($background, .68);
  $focus-bg:        rgba($background, .76);
  $hover-bg:        rgba($background, .92);
  $loading-bg:      $focus-bg;

  background: $background;
  box-shadow: $se-shadow-100;
  color: $color;

  &:hover {
    @include se-button-bg-overlay($hover-bg);
    box-shadow: $se-shadow-200;
  }

  &:focus {
    @include se-button-bg-overlay($focus-bg);
    box-shadow: $se-shadow-200;
  }

  &:active {
    @include se-button-bg-overlay($active-bg);
    box-shadow: $se-shadow-300;
  }

  &.is-loading {
    @include se-button-bg-overlay($loading-bg);
  }

  &:disabled {
    background: $disabled-bg;
    box-shadow: none;
  }

}
