.pl-text-divider {
  @include font-xxsmall;
  color: $color-content-light;
  display: block;
  overflow: hidden;
  position: relative;
  text-align: center;

  @include media-small {
    @include font-small;
  }
}

.pl-text-divider::before {
  border-top: 1px solid $gray-light;
  content: '';
  margin-left: calc(-10px - 50%);
  position: absolute;
  top: 50%;
  width: 50%;
}

.pl-text-divider::after {
  border-top: 1px solid $gray-light;
  content: '';
  margin-left: 10px;
  position: absolute;
  top: 50%;
  width: 50%;
}
